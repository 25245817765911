@import '../../styles/customMediaQueries.css';
.locationAutocompleteInputIcon {
  display: none;
}
.root {
  width: 100%;
  padding: 0.5rem;
  margin: auto;

  @media (--viewportMedium) {
    padding: 0.5rem;
    width: 400px;
  }
}
.map {
  margin-top: 1rem;
}
.title {
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 2.5rem;
  @media (--viewportMedium) {
    margin-left: 0.5rem;
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
.nextButton {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: white;
  padding: 0.5rem 1.25rem;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
  }
}
.nextButtonMobile {
  width: 100%;
  /* display: block;
  @media (--viewportMedium) {
    display: none;
  } */
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: white;
  padding: 0.5rem 1.25rem;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
  }
}
.cancelButton {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
  border: 1px solid var(--marketplaceColor);
  /* background-color: var(--marketplaceColor); */
  /* color: white; */
  padding: 0 1rem;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    /* background-color: var(--marketplaceColorDark); */
  }
}
.container {
  position: relative;
}
.priceCard {
  display: none;
  position: absolute;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  top: 50px;
  left: 0px;
  z-index: 2;
}
.priceCardOpen {
  position: absolute;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  top: 50px;
  left: 0px;
  z-index: 2;
}

.label {
  /* Button borders */
  border: 1px solid var(--colorGrey100);
  padding: 0.3rem 0.75rem;
  /* margin-right: 0.5rem; */
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--colorWhite);
  @media (--viewportMedium) {
    padding: 0.45rem 0.75rem;
  }
  &:hover {
    box-shadow: var(--boxShadowFilterButton);
  }
}
.labelActive {
  /* Button borders */
  border: 1px solid var(--colorGrey100);
  padding: 0.3rem 0.75rem;
  /* margin-right: 0.25rem; */
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--marketplaceColor);
  color: white;
  @media (--viewportMedium) {
    padding: 0.45rem 0.75rem;
  }
  &:hover {
    box-shadow: var(--boxShadowFilterButton);
  }
}
/* Style for the range input */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  width: 100%; /* Full-width */
  height: 10px !important; /* Height of the track */
  background: var(--marketplaceColor); /* Track color */
  border-radius: 10px; /* Rounded corners */
  outline: none; /* Remove outline */
  padding: 4px 16px 4px 16px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default thumb */
  appearance: none; /* Override default thumb */
  width: 30px; /* Width of the thumb */
  height: 30px; /* Height of the thumb */
  background: var(--marketplaceColorDark); /* Thumb color */
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor on hover */
}

.slider {
  &:focus {
    box-shadow: none;
  }
}
.modalContent {
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  /* max-height: calc(100vh - 150px); */
  overflow: scroll;
  /* @media (min-width: 400px) {
    max-height: calc(100vh - 80px);
  }
  max-height: calc(100vh);
  -webkit-overflow-scrolling: touch; */
}

.modal {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.locationContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
.leftArrow {
  display: block;
  font-size: 30px;
  margin-right: 0.25rem;
  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
  @media (--viewportMedium) {
    display: none;
  }
}
.locationInput {
  width: 100%;
  @media (--viewportMedium) {
    width: 100%;
  }
}
.locationAddress {
  width: 100%;
  @media (--viewportMedium) {
    width: 100%;
  }
}
.deleteIcon {
  position: absolute;
  right: 20px;
  background: white;
  @media (--viewportMedium) {
    right: 30px;
  }
}
.delete {
  display: block;
  color: var(--marketplaceColor);
  font-size: 30px;
  &:hover {
    cursor: pointer;
    color: var(--marketplaceColorDark);
  }
  @media (--viewportMedium) {
    /* display: none; */
  }
}
.mobileLabel {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.desktopLabel {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.sliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sliderLabel {
  margin: 0;
}
/* .mapbox {
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
} */
.mapMarker {
  font-size: 40px;
  color: var(--marketplaceColorDark);
}
.horizontalslider {
  height: 10px;
  background: #fff;
}

.thumb {
  height: 30px;
  width: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: grab;
  border-radius: 50%;
  border: 1px solid var(--marketplaceColor);
  color: #fff;
  transform: translate(0%, -30%);
}

.track {
  background: var(--marketplaceColor);
  height: 100%;
  border-radius: 5px;
}

.mark {
  height: 10px;
  width: 10px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.div {
  width: 96%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
