@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--colorGrey50);

  /* sticky result summary in mobile */
  position: sticky;
  top: 60px;
  z-index: 1;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--colorGrey50);
  display: none;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}
.floatingButton {
  position: fixed;
  bottom: 1rem;
  right: 40%;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.buttons {
  display: flex;
  margin-bottom: 0.5rem;
}
.location {
  margin-right: 0.5rem;
}
.mapIcon {
  font-weight: 600;
  text-align: center;
  background-color: var(--marketplaceColor);
  border-radius: 15px;
  width: 100px;
  min-height: 40px;
  padding: 9px 14px;
  margin: 0;
}
.mapIconR {
  color: white;
  fill: white;
  margin-left: 0.25rem;
}
.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--colorGrey100);
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 30px;
}
.filterIcon {
  font-size: 20px;
}
.count {
  position: absolute;
  top: 10px;
  left: 50px;
  font-size: 10px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
